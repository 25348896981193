// Core
import React, { useState } from 'react';

// Components
import { Card } from 'antd';

// Style
import '../css/about.css';

// Assets
import about from '../assets/about.png';
import values from '../assets/values.png';
import mission from '../assets/mission.png';

const About: React.FC = () => {
  const [flipped, setFlipped] = useState<{ [key: string]: boolean }>({});

  const handleFlip = (key: string) => {
    setFlipped((prevFlipped) => ({
      ...prevFlipped,
      [key]: !prevFlipped[key],
    }));
  };

  return (
    <div className="about-container">
      <div className={`about-card ${flipped['about'] ? 'flipped about' : 'about'}`} onClick={() => handleFlip('about')}>
      <Card className="about"
          title="Sobre nós"
          bordered={false}
          cover={<img alt="Sobre nós" src={about} />}
        >
          <h5>Sobre nós</h5>
          <p>
            Bem-vindo à Octasys Sistemas de Automação! Fundada em 2024, somos uma empresa comprometida em transformar a indústria por meio da automação e da Internet das Coisas (IoT). Nossa missão é proporcionar soluções inovadoras que aumentem a eficiência, segurança e produtividade dos nossos clientes.
          </p>
          <p>
            Localizados em Piracicaba, São Paulo, nossa equipe é formada por especialistas com vasta experiência no setor de automação industrial.
          </p>
          <p>
            Na Octasys, acreditamos em inovação contínua e em um atendimento ao cliente excepcional. Nossa dedicação em entender as necessidades dos nossos clientes nos permite oferecer soluções personalizadas que impulsionam o sucesso de seus negócios.
          </p>
        </Card>
      </div>

      <div className={`about-card ${flipped['values'] ? 'flipped values' : 'values'}`} onClick={() => handleFlip('values')}>
        <Card className="values"
          title="Nossos Valores"
          bordered={false}
          cover={<img alt="Nossos Valores" src={values} />}
        >
          <h5>Nossos Valores</h5>
          <p>Inovação: Estamos sempre em busca de novas soluções e tecnologias para melhorar a automação e IoT.</p>
          <p>Qualidade: Nos comprometemos com produtos e serviços de alta qualidade, garantindo a satisfação dos clientes.</p>
          <p>Transparência: Valorizamos a comunicação aberta e honesta com clientes, parceiros e colaboradores.</p>
          <p>Sustentabilidade: Implementamos soluções que atendem às necessidades atuais, respeitando o meio ambiente.</p>
          <p>Colaboração: Acreditamos no trabalho em equipe para alcançar os melhores resultados.</p>
          <p>Atendimento ao Cliente: Nossa prioridade é oferecer suporte excepcional em todas as etapas.</p>
        </Card>
      </div>

      <div className={`about-card ${flipped['mission'] ? 'flipped mission' : 'mission'}`} onClick={() => handleFlip('mission')}>
        <Card className="mission"
          title="Missão"
          bordered={false}
          cover={<img alt="Missão" src={mission} />}
        >
          <h5>Missão</h5>
          <p>
            Nossa missão na Octasys Sistemas de Automação é fornecer soluções inovadoras em automação industrial e IoT que aumentem a eficiência, segurança e competitividade dos nossos clientes. Buscamos entender as necessidades específicas de cada projeto e desenvolver sistemas personalizados que promovam a transformação digital e a sustentabilidade nas operações industriais.
          </p>
        </Card>
      </div>
    </div>
  );
};

export default About;