// Core
import React from 'react';

// Components
import { Card, Row, Col } from 'antd';

// Style
import '../css/services.css';

// Assets
import plc from '../assets/plc.webp';
import eletric from '../assets/electric.webp';
import plan from '../assets/plan.jpg';
import iot from '../assets/iot.webp';
import suporte from '../assets/supp.jpg';
import normas from '../assets/normas.webp';

const servicesData = [
    {
      title: 'CLP | IHM | Supervisório',
      description: 'Soluções personalizadas de automação com programação de CLPs, interfaces IHM e sistemas SCADA.',
      image: `${plc}`,
    },
    {
      title: 'Montagem de painel',
      description: 'Montagem de painéis elétricos seguros e eficientes para automação.',
      image: `${eletric}`,
    },
    {
      title: 'Projetos e esquemas',
      description: 'Criação de projetos elétricos para integrar sistemas de automação.',
      image: `${plan}`,
    },
    {
      title: 'Projetos IoT',
      description: 'Soluções IoT para conectar dispositivos e permitir monitoramento remoto.',
      image: `${iot}`,
    },
    {
      title: 'Suporte técnico',
      description: 'Assistência técnica rápida para garantir o funcionamento de sistemas de automação.',
      image: `${suporte}`,
    },
    {
      title: 'Adequação NR12',
      description: 'Automação conforme a NR12 para garantir segurança em máquinas e equipamentos.',
      image: `${normas}`,
    },
  ];
  
  const Services = () => {
    return (
      <div className="services-container">
        <h1>Nossos Serviços</h1>
        <Row gutter={[16, 24]}>
          {servicesData.map((service, index) => (
            <Col xs={24} sm={12} md={8} lg={8} key={index}> {/* Responsive column sizes */}
              <Card
                hoverable
                cover={<img alt={service.title} src={service.image} />}
                style={{ height: '100%' }}
              >
                <Card.Meta title={service.title} description={service.description} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };
  
  export default Services;
  