import React from 'react';
import { Form, Input, Button, message } from 'antd';
import emailjs from 'emailjs-com';

const ContactForm: React.FC = () => {
  const [form] = Form.useForm(); // Use form instance

  const onFinish = (values: any) => {
    const { name, email, numero, message: userMessage } = values;

    // EmailJS configuration
    emailjs.send(
      'service_crjo6fu',
      'template_31gw53r',
      { name, email, numero, message: userMessage },
      'FYOhc9HQjUZTh-CEw'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      message.success('Email enviado com sucesso!');
      form.resetFields(); // Clear the form fields only
    }, (err) => {
      console.error('FAILED...', err);
      message.error('Falha ao enviar o email. Tente novamente.');
    });
  };

  return (
    <Form form={form} name="contact-form" onFinish={onFinish} layout="vertical">
      <Form.Item
        name="name"
        label="Nome"
        rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
        style={{ marginBottom: '20px' }}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Por favor, insira seu email!' },
          { type: 'email', message: 'Por favor, insira um email válido!' },
        ]}
        style={{ marginBottom: '20px' }}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="numero"
        label="Número"
        rules={[
          { required: true, message: 'Por favor, insira seu número!' },
          { pattern: /^\d+$/, message: 'O número deve conter apenas dígitos!' }
        ]}
        style={{ marginBottom: '20px' }}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        name="message"
        label="Mensagem"
        rules={[{ required: true, message: 'Por favor, insira sua mensagem!' }]}
        style={{ marginBottom: '20px' }}
      >
        <Input.TextArea rows={4} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
