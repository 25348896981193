// Core
import React, { useState } from 'react';

// Components
import { Layout, Menu, Modal, Button, Drawer } from 'antd';
import ContactForm from './form.tsx';
import Services from './services.tsx';
import About from './about.tsx';

// Style
import '../css/layout.css';

// Assets
import { WhatsAppOutlined, MailOutlined, MenuOutlined, LoadingOutlined } from '@ant-design/icons'; // Icons
import Logo from '../assets/logo.png';

const { Header, Footer, Content } = Layout;

const LandingPage = () => {

  // States
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [activeContent, setActiveContent] = useState('home');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // Methods
  const handleContactClick = () => {
    setIsModalVisible(true); // Open the initial modal with options
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/+5519971387389', '_blank');
    setIsModalVisible(false); // Close the modal after redirecting to WhatsApp
  };

  const handleEmailClick = () => {
    setShowForm(true); // Immediately show the form
  };

  const handleModalClose = () => {
    setIsModalVisible(false); // Close modal and reset form visibility
    setShowForm(false); 
  };

  const handleMenuClick = (key) => {
    setLoading(true); // Start loading
    setActiveContent(key); // Update active content based on menu item clicked
    setTimeout(() => setLoading(false), 1000); // Simulate loading delay (1 second)
  };  

  const showDrawer = () => {
    setIsDrawerVisible(true); // Open the drawer
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false); // Close the drawer
  };

  // Function to get the current year
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  // Custom loading spinner
  const loadingIndicator = (
    <LoadingOutlined style={{ fontSize: '100px', color: 'rgb(5,248,167)' }} spin />
  );

  return (
    <Layout className="layout">
      {/* Header Section */}
      <Header className="header">
        <div className="logo" onClick={() => handleMenuClick('home')} style={{ cursor: 'pointer' }}>
          <img src={Logo} alt="Logo" className="logo-img" />
        </div>

        {/* Hamburger menu for mobile (will be shown in mobile view) */}
        <div className="hamburger-menu">
          <Button icon={<MenuOutlined />} className="menu-icon" onClick={showDrawer} />
        </div>

        {/* Drawer for mobile menu */}
        <Drawer
          title="Menu"
          placement="right"
          onClose={closeDrawer}
          open={isDrawerVisible}
        >
          <Menu mode="vertical" defaultSelectedKeys={['1']}>
            <Menu.Item key="1" onClick={() => handleMenuClick('home')}>
              Home
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleMenuClick('servicos')}>
              Serviços
            </Menu.Item>
            <Menu.Item key="3" onClick={() => handleMenuClick('sobre')}>
              Sobre
            </Menu.Item>
          </Menu>
        </Drawer>

        {/* Regular menu for desktop (will be shown on larger screens) */}
        <div className="header-right">
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1" onClick={() => handleMenuClick('home')}>
              Home
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleMenuClick('servicos')}>
              Serviços
            </Menu.Item>
            <Menu.Item key="3" onClick={() => handleMenuClick('sobre')}>
              Sobre
            </Menu.Item>
          </Menu>
        </div>
      </Header>

      {/* Content Section */}
      <Content className="content-background">
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            {loadingIndicator} {/* Display loading spinner */}
          </div>
        ) : (
          <>
            {activeContent === 'servicos' && <Services />}
            {activeContent === 'sobre' && <About />}
            {activeContent === 'home' && (
              <div className="site-layout-content">
                <h1>Revolucione seu negócio com automação</h1>
                <p>Aumente a eficiência, economize tempo e melhore a precisão com nossas soluções avançadas de automação.</p>
                <Button className="custom-button" onClick={handleContactClick}>
                  Entre em contato
                </Button>
              </div>
            )}
          </>
        )}
      </Content>

      {/* Footer Section */}
      <Footer className="site-layout-footer">
        ©{getCurrentYear()} Octasys. Todos os direitos reservados.
      </Footer>

      {/* Modal for choosing WhatsApp or Email */}
      <Modal
        title="Escolha uma opção de contato"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {/* If 'showForm' is false, show icons for WhatsApp and Email */}
        {!showForm ? (
          <div style={{ textAlign: 'center' }}>
            <Button
              icon={<WhatsAppOutlined />}
              size="large"
              className="custom-whatsapp-button"
              onClick={handleWhatsAppClick}
            >
              WhatsApp
            </Button>
            <Button
              icon={<MailOutlined />}
              size="large"
              className="custom-email-button"  // Use the custom class
              onClick={handleEmailClick}
            >
              Email
            </Button>
          </div>
        ) : (
          <ContactForm /> // Immediately show the email form if 'Enviar Email' is clicked
        )}
      </Modal>
    </Layout>
  );
};

export default LandingPage;